import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold, smoothScroll } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()
  // scrollSpy('.header-nav-sub:visible', {offset: 0})
  smoothScroll('a[href^="#"]', {offset: 250})
})

$('.description--wrapper .close').on('click', function () {
  $('.model-viewer .description--wrapper').removeClass('active')
})

const selector = $('#dimension-demo .hotspot')
$(selector).on('click', function () {
  $(selector).removeClass('selected')
  $(this).addClass('selected')

  const currentItem = $('.model-viewer .hotspot.selected')

  function initializeDescription () {
    render(getDescriptionOf(currentItem) || '')
  }

  function getDescriptionOf (currentItem) {
    return $(currentItem).data('description')
  }

  function render (descriptionText) {
    const description = $('.model-viewer .description')
    if (descriptionText == null) throw new Error('Undefined description')
    $(description).html(descriptionText)
  }

  initializeDescription()
  render(getDescriptionOf(currentItem))
  $('.description--wrapper').addClass('active')
})

$('.carousel').carousel({
  interval: false
})

$(document).ready(function () {
  $('#pumpentypen').hover(function () {
    $('#pumpentypen-content').toggleClass('active')
    $('.content-circle').toggleClass('active')
  })

  $('#pump-design').hover(function () {
    $('#pump-design-content').toggleClass('active')
    $('.content-circle').toggleClass('active')
  })

  $('#pumping-media').hover(function () {
    $('#pumping-media-content').toggleClass('active')
    $('.content-circle').toggleClass('active')
  })

  $('#hermetic').hover(function () {
    $('#hermetic-content').toggleClass('active')
    $('.content-circle').toggleClass('active')
  })

  $('#applications').hover(function () {
    $('#applications-content').toggleClass('active')
    $('.content-circle').toggleClass('active')
  })

  $('#contact').hover(function () {
    $('#contact-content').toggleClass('active')
    $('.content-circle').toggleClass('active')
  })

  $('#training').hover(function () {
    $('#training-content').toggleClass('active')
    $('.content-circle').toggleClass('active')
  })
})

$(document).ready(function () {
  $('.downloadPopup').on('click', function () {
    $('#webinar-form').show()
    $('#webinar-form').addClass('active')
  })
})

$(document).ready(function () {
  $('.banner-cta').on('click', function () {
    $('#bookletForm').show()
    $('#bookletForm').addClass('active')
  })
})

$(document).ready(function () {
  $('.pumpenauslegung').on('click', function () {
    $('#form').show()
    $('#form').addClass('active')
  })
})

$('#pump-technology').hover(function () {
  $('#pump-technology-content').toggleClass('active')
  $('.content-circle').toggleClass('active')
})


$(document).ready(function () {
  var modalAdvent = document.getElementById('bookletForm')
  var modal = document.getElementById('webinar-form')
  var modalForm = document.getElementById('form')
  window.onclick = function (event) {
    if (event.target === modal) {
      modal.style.display = 'none'
    } else if (event.target === modalAdvent) {
      modalAdvent.style.display = 'none'
    } else if (event.target === modalForm) {
      modalForm.style.display = 'none'
    }
  }
})

const cards = $('.advent-section');
for(var i = 0; i < cards.length; i++) {
  var target = Math.floor(Math.random() * cards.length -2) + 2
  var target2 = Math.floor(Math.random() * cards.length -1) +1
  cards.eq(target).before(cards.eq(target2))
}

$(window).on('load resize', function () {
  function distanceBetweenElements (elementOne, elementTwo) {
    let distance = -1

    const x1 = elementOne.offset().top
    const y1 = elementOne.offset().left
    const x2 = elementTwo.offset().top
    const y2 = elementTwo.offset().left
    const xDistance = x1 - x2
    const yDistance = y1 - y2

    distance = Math.sqrt(
      (xDistance * xDistance) + (yDistance * yDistance)
    )

    return distance + 'px'
  }

  const circleApplication = $('#applications')
  const circleTraining = $('#training')
  const circlePumpentypen = $('#pumpentypen')
  const circleKontakt = $('#contact')
  const circleHermetic = $('#hermetic')
  const circleFoerdermedien = $('#pumping-media')
  const circlePumpenauslegung = $('#pump-design')
  const circlePumpentechnologie = $('#pump-technology')

  const spaceBetweenApplicationTraining = distanceBetweenElements(circleApplication, circleTraining)

  const spaceBetweenApplicationPumpenypen = distanceBetweenElements(circleApplication, circlePumpentypen)

  const spaceBetweenTrainingPumpenypen = distanceBetweenElements(circleTraining, circlePumpentypen)

  const spaceBetweenTrainingKontakt = distanceBetweenElements(circleTraining, circleKontakt)

  const spaceBetweenKontaktApplikationen = distanceBetweenElements(circleKontakt, circleApplication)

  const spaceBetweenKontaktHermetic = distanceBetweenElements(circleKontakt, circleHermetic)

  const spaceBetweenHermeticApplication = distanceBetweenElements(circleHermetic, circleApplication)

  const spaceBetweenHermeticTraining = distanceBetweenElements(circleHermetic, circleTraining)

  const spaceBetweenFoerdermedienHermetic = distanceBetweenElements(circleFoerdermedien, circleHermetic)

  const spaceBetweenFoerdermedienApplication = distanceBetweenElements(circleFoerdermedien, circleApplication)

  const spaceBetweenPumpenauslegungFoerdermedien = distanceBetweenElements(circlePumpenauslegung, circleFoerdermedien)

  const spaceBetweenPumpenauslegungHermetic = distanceBetweenElements(circlePumpenauslegung, circleHermetic)

  const spaceBetweenPumpentypenPumpenauslegung = distanceBetweenElements(circlePumpentypen, circlePumpenauslegung)

  const spaceBetweenPumpentypenFoerdermedien = distanceBetweenElements(circlePumpentypen, circlePumpentechnologie)

  const spaceBetweenPumpenauslegungPumpentechnologie = distanceBetweenElements(circlePumpenauslegung, circlePumpentechnologie)

  const spaceBetweenPumpentechnologieFoerdermedien = distanceBetweenElements(circlePumpentechnologie, circleFoerdermedien)

  $('.content-circle.applications .before').css('--widthLine', (spaceBetweenApplicationPumpenypen))

  $('.content-circle.applications .after').css('--widthLine', (spaceBetweenApplicationTraining))

  $('.content-circle.training .before').css('--widthLine', (spaceBetweenTrainingPumpenypen))

  $('.content-circle.training .after').css('--widthLine', (spaceBetweenTrainingKontakt));

  $('.content-circle.contact .before').css('--widthLine', (spaceBetweenKontaktApplikationen))

  $('.content-circle.contact .after').css('--widthLine', (spaceBetweenKontaktHermetic));

  $('.content-circle.hermetic .before').css('--widthLine', (spaceBetweenHermeticApplication))

  $('.content-circle.hermetic .after').css('--widthLine', (spaceBetweenHermeticTraining));

  $('.content-circle.pumped-media .before').css('--widthLine', (spaceBetweenFoerdermedienHermetic))

  $('.content-circle.pumped-media .after').css('--widthLine', (spaceBetweenFoerdermedienApplication))

  $('.content-circle.pump-design .before').css('--widthLine', (spaceBetweenPumpenauslegungFoerdermedien))

  $('.content-circle.pump-design .after').css('--widthLine', (spaceBetweenPumpenauslegungHermetic))

  $('.content-circle.pumps .before').css('--widthLine', (spaceBetweenPumpentypenPumpenauslegung))

  $('.content-circle.pumps .after').css('--widthLine', (spaceBetweenPumpentypenFoerdermedien))

  $('.content-circle.pump-technology .before').css('--widthLine', (spaceBetweenPumpenauslegungPumpentechnologie))

  $('.content-circle.pump-technology .after').css('--widthLine', (spaceBetweenPumpentechnologieFoerdermedien))
})

$('.carousel.case-studies-slider .neos-contentcollection').slick({
  centerMode: true,
  centerPadding: '100px',
  slidesToShow: 2,
  arrows: true,
  dots: true,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 1
      }
    },
    {
      breakpoint: 992,
      settings: {
        centerPadding: '60px',
        slidesToShow: 1
      }
    },
    {
      breakpoint: 511,
      settings: {
        slidesToShow: 1,
        centerPadding: '30px',
        swipeToSlide: true,
        swipe: true,
        touchMove: true,
      }
    },
    {
      breakpoint: 426,
      settings: {
        slidesToShow: 1,
        centerPadding: '0'
      }
    }
  ]
})

$('#go-back').on('click', function () {
  // eslint-disable-next-line no-undef
  history.back()
})

const puzzles = [
  {
    grid: [
      [0, 0, '4', 0, 0, 0, 0, 0, 0, 0],
      [0, 0, '4', 0, 0, 0, 0, 0, 0, '1'],
      [0, 0, '4', 0, 0, 0, 0, 0, 0, '1'],
      ['5', 0, '4', 0, 0, 0, 0, 0, 0, '1'],
      ['5', 0, '4', 0, 0, 0, 0, 0, 0, '1'],
      ['5', 0, '4', 0, 0, 0, 0, 0, 0, '1'],
      ['5', 0, '4', 0, 0, 0, 0, 0, 0, '1'],
      ['5', 0, '4', 0, 0, 0, 0, 0, 0, '1'],
      ['5', 0, '4', 0, 0, 0, 0, 0, 0, '1'],
      ['5', 0, '4', 0, 0, 0, 0, 0, 0, '1'],
      ['5', 0, '4', 0, 0, 0, 0, 0, 0, '1'],
      ['5', 0, '4', 0, 0, 0, 0, 0, 0, '1'],
      ['5,2', '2', '4,2', '2', '3,2', '2', '2', '2', '2', '1,2'],
      ['5', 0, '4', 0, '3', 0, 0, 0, 0, '1'],
      ['5', 0, '4', 0, '3', 0, 0, 0, 0, '1'],
      ['5', 0, '4', 0, '3', 0, 0, 0, 0, '1'],
      ['5', 0, '4', 0, '3', 0, 0, 0, 0, '1'],
      ['5', 0, '4', 0, '3', 0, 0, 0, 0, '1'],
      ['5', 0, '4', 0, '3', 0, 0, 0, 0, '1'],
      [0, 0, '4', 0, 0, 0, 0, 0, 0, 0],
      [0, 0, '4', 0, 0, 0, 0, 0, 0, 0]
    ],
    clues: [
      'Was kann ein möglicher Grund für Trockenlauf sein?',
      'Welches Bauteil leidet vom Trockenlauf?',
      'Was passiert, durch Trockenlauf verursacht, wenn die Welle schräggestellt ist?',
      'Was kann in der Anlage eingebaut werden, um den Trockenlauf zu vermeiden?',
      'Beim Abschalten der Pumpe besteht die Gefahr, dass das Fördermedium zurückströmt und somit beim Neustart die Pumpe ohne Flüssigkeit anläuft. Welche Lösung gibt es? '
    ],
    answers: [
      'Temperaturerhöhung',
      'Gleitlager',
      'Torsion',
      'Füllstandsüberwachung',
      'Rückschlagventil'
    ]
  },
  {
    grid: [
      ['4', 0, 0, 0, 0, 0, 0],
      ['4', 0, 0, 0, 0, 0, 0],
      ['4', 0, 0, 0, 0, 0, '1'],
      ['4', 0, 0, 0, 0, 0, '1'],
      ['4', 0, 0, 0, 0, 0, '1'],
      ['4', 0, 0, 0, 0, 0, '1'],
      ['4', 0, 0, 0, 0, 0, '1'],
      ['4', 0, '5', 0, 0, 0, '1'],
      ['4', 0, '5', 0, 0, 0, '1'],
      ['4', 0, '5', 0, 0, 0, '1'],
      ['4', 0, '5', 0, 0, 0, '1'],
      ['4', 0, '5', 0, '2', 0, '1'],
      ['4', 0, '5', 0, '2', 0, '1'],
      ['4', 0, '5', 0, '2', 0, '1'],
      ['4', 0, '5', 0, '2', 0, '1'],
      ['3,4', '3', '3,5', '3', '3,2', '3', '3,1'],
      ['4', 0, '5', 0, '2', 0, '1'],
      ['4', 0, '5', 0, '2', 0, '1'],
      ['4', 0, '5', 0, 0, 0, '1'],
      ['4', 0, '5', 0, 0, 0, '1'],
      ['4', 0, '5', 0, 0, 0, '1'],
      [0, 0, '5', 0, 0, 0, '1'],
      [0, 0, '5', 0, 0, 0, 0]
    ],
    clues: [
      'What can be a possible reason for dry running?',
      'Which component suffers from dry running?',
      'Due to dry running, what can happen to the pump shaft if it has an inclined position?',
      'What can be installed in the system to prevent dry running?',
      'When the pump is switched off, there is a risk that the pumped liquid will flow back and therefore the pump will start up without liquid when it is restarted. What is the solution?'
    ],
    answers: [
      'Temperature&increase',
      'Bearing',
      'Torsion',
      'Fill&level&monitoring',
      'Non&return&valve'
    ]
  },
  {
    grid: [
      [0, 0, '4', 0, 0, 0, 0],
      [0, 0, '4', 0, 0, 0, 0],
      [0, 0, '4', 0, 0, 0, 0],
      [0, 0, '4', 0, 0, 0, 0],
      [0, 0, '4', 0, '1', 0, 0],
      ['2', 0, '4', 0, '1', 0, 0],
      ['2', 0, '4', 0, '1', 0, 0],
      ['2', 0, '4', 0, '1', 0, 0],
      ['2', 0, '4', 0, '1', 0, 0],
      ['2', 0, '4', 0, '1', 0, 0],
      ['2', 0, '4', 0, '1', 0, 0],
      ['2', 0, '4', 0, '1', 0, 0],
      ['2', 0, '4', 0, '1', 0, '5'],
      ['2,3', '3', '4,3', '3', '1,3', '3', '5,3'],
      ['2', 0, '4', 0, '1', 0, '5'],
      ['2', 0, '4', 0, 0, 0, '5'],
      ['2', 0, '4', 0, 0, 0, '5'],
      ['2', 0, '4', 0, 0, 0, '5'],
      ['2', 0, '4', 0, 0, 0, '5'],
      ['2', 0, '4', 0, 0, 0, 0],
      ['2', 0, '4', 0, 0, 0, 0],
      ['2', 0, '4', 0, 0, 0, 0],
      ['2', 0, '4', 0, 0, 0, 0],
      ['2', 0, '4', 0, 0, 0, 0],
      ['2', 0, '4', 0, 0, 0, 0]
    ],
    clues: [
      'Was entsteht, wenn bei Kavitation der statische Druck auf oder unter den Verdampfungsdruck der Flüssigkeit fällt?',
      'Welches ist eine der Hauptursachen (saugseitig), die zu Kavitation führen?',
      'Wie macht sich Kavitation im laufenden Anlagenbetrieb bemerkbar?',
      'Wofür steht die Abkürzung „NPSH“?',
      'Welches Bauteil kommt gerne zum Einsatz, um den NPSHr-Wert zu reduzieren, wenn man keine Möglichkeit hat, den NPSHa-Wert zu erhöhen?'
    ],
    answers: [
      'Dampfblasen',
      'Sinkender&NPSHa&Wert',
      'Rasseln',
      'Net&Positive&Suction&Head',
      'Inducer'
    ]
  },
  {
    grid: [
      [0, 0, 0, 0, 0, 0, '4', 0],
      [0, 0, 0, 0, 0, 0, '4', 0],
      [0, 0, 0, 0, 0, 0, '4', 0],
      [0, 0, 0, 0, '1', 0, '4', 0],
      [0, '2', 0, 0, '1', 0, '4', 0],
      [0, '2', 0, 0, '1', 0, '4', 0],
      [0, '2', 0, 0, '1', 0, '4', 0],
      [0, '2', 0, 0, '1', 0, '4', 0],
      [0, '2', 0, 0, '1', 0, '4', 0],
      [0, '2', 0, 0, '1', 0, '4', 0],
      [0, '2', 0, 0, '1', 0, '4', 0],
      [0, '2,5', '5', '5', '1,5', '5', '4,5', '5'],
      [0, '2', 0, 0, '1', 0, '4', 0],
      [0, '2', 0, 0, '1', 0, '4', 0],
      [0, '2', 0, 0, '1', 0, '4', 0],
      [0, '2', 0, 0, '1', 0, '4', 0],
      [0, '2', 0, 0, '1', 0, '4', 0],
      [0, '2', 0, 0, 0, 0, '4', 0],
      [0, '2', 0, 0, 0, 0, '4', 0],
      ['3', '2,3', '3', '3', '3', '3', '4,3', '3'],
      [0, '2', 0, 0, 0, 0, '4', 0],
      [0, '2', 0, 0, 0, 0, '4', 0],
      [0, '2', 0, 0, 0, 0, '4', 0],
      [0, '2', 0, 0, 0, 0, '4', 0],
      [0, '2', 0, 0, 0, 0, '4', 0],
      [0, '2', 0, 0, 0, 0, 0, 0],
    ],
    clues: [
      'What happens when cavitation causes the static pressure to fall to or below the evaporation pressure of the liquid?',
      'What is one of the main causes (on the suction side) that lead to cavitation?',
      'How is cavitation noticeable during system operation?',
      'What does the abbreviation "NPSH" stand for?',
      'Which component is often used to reduce the NPSHr value if there is no way to increase the NPSHa value?'
    ],
    answers: [
      'Vapour&bubbles',
      'Decreasing&NPSHa&value',
      'Rattling',
      'Net&Positive&Suction&Head',
      'Inducer'
    ]
  },
  {
    grid: [
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, '1', 0, 0, 0, 0, 0],
      [0, 0, '4', '4', '4', '4', '4', '4', '4', '4', '4', '4', '4', '4,1', '4', 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, '1', 0, 0, 0, 0, 0],
      ['2', '2', '2', '2', '2', '2', '2', '2', '2', '2', '2', '2', '2', '2,1', '2', '2', '2', '2', 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, '1', 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, '1', 0, 0, 0, 0, 0],
      [0, '3', '3', '3', '3', '3', '3', '3', '3', '3', '3', '3', '3', '3,1', '3', '3', '3', '3', '3'],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, '1', 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, '1', 0, 0, 0, 0, 0]
    ],
    clues: [
      'Was ist eine häufige Folge bei der falschen Werkstoffauswahl der Pumpe?',
      'Welche Kenndaten sollten unter anderem bei der Pumpenauslegung angegeben werden?',
      'Welche Auswirkungen auf dem Pumpenbetrieb kann eine zu geringe Viskosität des Fördermediums haben?',
      'Welches HERMETIC Tool kann Ihnen bei der Pumpenkonfiguration in der Chemie, Petrochemie oder Kältetechnik behilflich sein?'
    ],
    answers: [
      'Korrosion',
      'Betriebstemperatur',
      'Lagertragfähigkeit',
      'Experten&Tool'
    ]
  },
  {
    grid: [
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, '1', 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, '1', 0, 0],
      ['2', '2', '2', '2', '2', '2', '2', '2', '2', '2', '2', '2', '2', '2', '2', '2', '2', '2', '2', '2,1', '2', 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, '1', 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, '4', '4', '4', '4', '4', '4', '4', '4', '4,1', '4', '4'],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, '1', 0, 0],
      [0, '3', '3', '3', '3', '3', '3', '3', '3', '3', '3', '3', '3', '3', '3', '3', '3', '3', '3', '3,1', '3', '3'],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, '1', 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, '1', 0, 0]
    ],
    clues: [
      'What is a common consequence of selecting the wrong pump material?',
      'Which characteristic data should be specified for the pump design?',
      'What effects on the pump operation can a too low viscosity of the pumped liquid have?',
      'Which HERMETIC tool can help you with pump configuration in the chemical, petrochemical or refrigeration industry?'
    ],
    answers: [
      'Corrosion',
      'Operating&temperature',
      'Bearing&load&capacity',
      'Expert&Tool'
    ]
  },
  {
    grid: [
      [0, 0, '3', 0, 0, 0, 0, 0],
      ['4', 0, '3', 0, 0, '1', 0, 0],
      ['4,2', '2', '3,2', '2', '2', '1,2', '2', '2'],
      ['4', 0, '3', 0, 0, '1', 0, 0],
      ['4', 0, '3', 0, 0, '1', 0, 0],
      ['4', 0, '3', 0, 0, '1', 0, 0],
      ['4', 0, '3', 0, 0, '1', 0, 0],
      ['4', 0, '3', 0, 0, 0, 0, 0],
      [0, 0, '3', 0, 0, 0, 0, 0],
    ],
    clues: [
      'Was können Sie in die Pumpe einbauen, um zu verhindern, dass Feststoffe in die Saugseite der Pumpe gelangen?',
      'Wie wird der mechanische Angriff auf die Werkstoffoberfläche der Bauteile der Pumpe durch Feststoffe im Fördermedium genannt?',
      'Was ist eine mögliche Folge von Abrasion?',
      'Welches Bauteil der Pumpe kann möglicherweise durch ein verschmutztes Fördermedium zugesetzt werden?'
    ],
    answers: [
      'Filter',
      'Abrasion',
      'Korrosion',
      'Laufrad'
    ]
  },
  {
    grid: [
      [0, 0, 0, 0, 0, '1', 0, '3'],
      [0, 0, 0, 0, 0, '1', 0, '3'],
      ['4', '4', '4', '4', '4', '1,4', '4', '3,4'],
      [0, 0, 0, 0, 0, '1', 0, '3'],
      [0, 0, 0, 0, 0, '1', 0, '3'],
      [0, 0, 0, 0, 0, '1', 0, '3'],
      [0, 0, 0, 0, 0, 0, 0, '3'],
      [0, 0, 0, 0, 0, 0, 0, '3'],
      ['2', '2', '2', '2', '2', '2', '2', '3,2'],

    ],
    clues: [
      'What can you install in the pump to prevent solids from entering the suction side of the pump?',
      'What is the mechanical attack on the material surface of the pump components by solids in the pumped medium called?',
      'What is a possible consequence of abrasion?',
      'Which component of the pump can possibly be clogged by a contaminated pumped liquid?'
    ],
    answers: [
      'Filter',
      'Abrasion',
      'Corrosion',
      'Impeller'
    ]
  }
]

// eslint-disable-next-line camelcase
function get_direction(puzzle, question_number) {
  for (let i = 0; i < puzzle.grid.length; i++) {
    for (let j = 0; j < puzzle.grid[i].length; j++) {
      if (String(puzzle.grid[i][j]).includes(question_number)) {
        if (i < puzzle.grid.length - 1 && String(puzzle.grid[i + 1][j]).includes(question_number)) {
          return 'vertical';
        }

        if (j < puzzle.grid[i].length - 1 && String(puzzle.grid[i][j + 1]).includes(question_number)) {
          return 'horizontal';
        }
      }
    }
  }
}

function get_startpos(puzzle, question_number, direction) {
  for (let i = 0; i < puzzle.grid.length; i++) {
    for (let j = 0; j < puzzle.grid[i].length; j++) {
      if (String(puzzle.grid[i][j]).includes(question_number)) {
        return direction === 'horizontal' ? [i, j] : [j, i];
      }
    }
  }
}

function drawPuzzle (puzzle, targetDiv) {
  const table = $(targetDiv + ' .puzzle-grid')
  $.each(puzzle.grid, function (i) {
    var row = $('<tr></tr>')

    $.each(this, function (j) {
      if (this == 0) {
        $(row).append('<td class="square empty editable"></td>')
      } else {
        // eslint-disable-next-line camelcase
        const question_number = String(puzzle.grid[i][j]).split(',')

        // eslint-disable-next-line camelcase
        let starting_number = ''
        // eslint-disable-next-line camelcase
        let question_number_span = ''

        for (var k = 0; k < question_number.length; k++) {
          var direction = get_direction(puzzle, question_number[k])
          var startpos = get_startpos(puzzle, question_number[k], direction)

          if (
            direction == 'horizontal' &&
            startpos[0] == i &&
            startpos[1] == j
          ) {
            starting_number += question_number[k] + ','
          } else if (
            direction == 'vertical' &&
            startpos[0] == j &&
            startpos[1] == i
          ) {
            starting_number += question_number[k] + ','
          }
        }
        if (starting_number != '') {
          question_number_span =
            '<span class="question_number">' +
            starting_number.replace(/(^,)|(,$)/g, '') +
            '</span>'
        }

        $(row).append(
          '<td>' +
            question_number_span +
            '<div class="square letter editable" data-number="' +
            this +
            '" contenteditable="true"></div></td>'
        )
      }
    })

    

    table.append(row)

  })
}

function drawHints (puzzle, targetDiv) {
  // eslint-disable-next-line camelcase
  const vertical_hints = $('<div class="vertical-hints"></div>')
  // eslint-disable-next-line camelcase
  const horizontal_hints = $('<div class="horizontal-hints"></div>')
  $.each(puzzle.clues, function (index) {
    var direction = get_direction(puzzle, index + 1);

    if (direction == 'horizontal') {
      $(horizontal_hints).append(
        '<div class="hint"><b>' +
          (index + 1) +
          '.</b>' +
          puzzle.clues[index] +
          '</hint>'
      );
    } else if (direction == 'vertical') {
      $(vertical_hints).append(
        '<div class="hint"><b>' +
          (index + 1) +
          '.</b>' +
          puzzle.clues[index] +
          '</hint>'
      )
    }
  })
  $(targetDiv + ' .vertical-hints-container').append(vertical_hints)
  $(targetDiv + ' .horizontal-hints-container').append(horizontal_hints)
}

function fillCorrectAnswers (puzzle, targetDiv) {
  for (let i = 0; i < puzzle.answers.length; i++) {
    const answer = puzzle.answers[i].toLowerCase()
    const direction = get_direction(puzzle, i + 1)
    const startpos = get_startpos(puzzle, i + 1, direction)

    for (let j = 0; j < answer.length; j++) {
      const letterToInsert = answer[j] === '&' ? '&' : null

      if (direction === 'horizontal') {
        const cell = $(targetDiv + ' .puzzle-grid tr:nth-child(' + (startpos[0] + 1) + ') td:nth-child(' + (startpos[1] + 1 + j) + ') div')
        cell.text(letterToInsert)

        if (letterToInsert === '&') {
          cell.addClass('disabled')
          cell.attr('contenteditable', 'false')
        }
      } else if (direction === 'vertical') {
        const cell = $(targetDiv + ' .puzzle-grid tr:nth-child(' + (startpos[1] + 1 + j) + ') td:nth-child(' + (startpos[0] + 1) + ') div');
        cell.text(letterToInsert)

        if (letterToInsert === '&') {
          cell.addClass('disabled')
          cell.attr('contenteditable', 'false')
        }
      }
    }
  }
}

const preFilledLetters = [
  { puzzleIndex: 0, row: 3, col: 9, letter: 'M' },
  { puzzleIndex: 0, row: 6, col: 9, letter: 'R' },
  { puzzleIndex: 0, row: 14, col: 9, letter: 'Ö' },
  { puzzleIndex: 0, row: 3, col: 2, letter: 'L' },
  { puzzleIndex: 0, row: 9, col: 0, letter: 'H' },
  { puzzleIndex: 0, row: 10, col: 2, letter: 'Ü' },
  { puzzleIndex: 0, row: 14, col: 2, letter: 'W' },
  { puzzleIndex: 0, row: 12, col: 4, letter: 'T' },
  { puzzleIndex: 0, row: 18, col: 0, letter: 'L' },

  { puzzleIndex: 1, row: 2, col: 0, letter: 'L' },
  { puzzleIndex: 1, row: 7, col: 0, letter: 'V' },
  { puzzleIndex: 1, row: 12, col: 0, letter: 'O' },
  { puzzleIndex: 1, row: 17, col: 0, letter: 'R' },
  { puzzleIndex: 1, row: 9, col: 2, letter: 'N' },
  { puzzleIndex: 1, row: 15, col: 2, letter: 'R' },
  { puzzleIndex: 1, row: 20, col: 2, letter: 'L' },
  { puzzleIndex: 1, row: 15, col: 4, letter: 'I' },
  { puzzleIndex: 1, row: 5, col: 6, letter: 'P' },
  { puzzleIndex: 1, row: 9, col: 6, letter: 'T' },
  { puzzleIndex: 1, row: 17, col: 6, letter: 'R' },

  { puzzleIndex: 2, row: 8, col: 0, letter: 'K' },
  { puzzleIndex: 2, row: 8, col: 2, letter: 'T' },
  { puzzleIndex: 2, row: 8, col: 4, letter: 'F' },
  { puzzleIndex: 2, row: 13, col: 2, letter: 'S' },
  { puzzleIndex: 2, row: 15, col: 0, letter: 'N' },
  { puzzleIndex: 2, row: 16, col: 6, letter: 'C' },
  { puzzleIndex: 2, row: 17, col: 2, letter: 'I' },
  { puzzleIndex: 2, row: 19, col: 0, letter: 'A' },
  { puzzleIndex: 2, row: 23, col: 2, letter: 'A' },

  { puzzleIndex: 3, row: 6, col: 1, letter: 'C' },
  { puzzleIndex: 3, row: 9, col: 1, letter: 'A' },
  { puzzleIndex: 3, row: 15, col: 1, letter: 'N' },
  { puzzleIndex: 3, row: 19, col: 1, letter: 'A' },
  { puzzleIndex: 3, row: 23, col: 1, letter: 'L' },
  { puzzleIndex: 3, row: 6, col: 4, letter: 'O' },
  { puzzleIndex: 3, row: 12, col: 4, letter: 'B' },
  { puzzleIndex: 3, row: 19, col: 2, letter: 'T' },
  { puzzleIndex: 3, row: 8, col: 6, letter: 'T' },
  { puzzleIndex: 3, row: 17, col: 6, letter: 'I' },
  { puzzleIndex: 3, row: 21, col: 6, letter: 'H' },
  { puzzleIndex: 3, row: 11, col: 5, letter: 'C' },

  { puzzleIndex: 4, row: 1, col: 3, letter: 'X' },
  { puzzleIndex: 4, row: 1, col: 7, letter: 'T' },
  { puzzleIndex: 4, row: 1, col: 12, letter: 'O' },
  { puzzleIndex: 4, row: 3, col: 1, letter: 'E' },
  { puzzleIndex: 4, row: 3, col: 10, letter: 'M' },
  { puzzleIndex: 4, row: 3, col: 13, letter: 'R' },
  { puzzleIndex: 4, row: 6, col: 3, letter: 'G' },
  { puzzleIndex: 4, row: 6, col: 9, letter: 'G' },
  { puzzleIndex: 4, row: 6, col: 11, letter: 'Ä' },
  { puzzleIndex: 4, row: 6, col: 15, letter: 'K' },

  { puzzleIndex: 5, row: 2, col: 2, letter: 'E' },
  { puzzleIndex: 5, row: 2, col: 12, letter: 'M' },
  { puzzleIndex: 5, row: 2, col: 19, letter: 'R' },
  { puzzleIndex: 5, row: 4, col: 12, letter: 'X' },
  { puzzleIndex: 5, row: 4, col: 20, letter: 'O' },
  { puzzleIndex: 5, row: 6, col: 4, letter: 'R' },
  { puzzleIndex: 5, row: 6, col: 7, letter: 'G' },
  { puzzleIndex: 5, row: 6, col: 11, letter: 'A' },
  { puzzleIndex: 5, row: 6, col: 19, letter: 'I' },
  { puzzleIndex: 5, row: 6, col: 21, letter: 'Y' },

  { puzzleIndex: 6, row: 2, col: 0, letter: 'A' },
  { puzzleIndex: 6, row: 2, col: 3, letter: 'A' },
  { puzzleIndex: 6, row: 4, col: 2, letter: 'O' },
  { puzzleIndex: 6, row: 4, col: 5, letter: 'T' },

  { puzzleIndex: 7, row: 2, col: 2, letter: 'P' },
  { puzzleIndex: 7, row: 2, col: 5, letter: 'L' },
  { puzzleIndex: 7, row: 3, col: 7, letter: 'R' },
  { puzzleIndex: 7, row: 7, col: 7, letter: 'O' },
  { puzzleIndex: 7, row: 8, col: 5, letter: 'I' },
]

function fillPreFilledLetters (puzzle, targetDiv) {
  preFilledLetters.forEach(item => {
    const { puzzleIndex, row, col, letter } = item

    if (puzzleIndex === puzzle) {
      const cell = $(targetDiv + ' .puzzle-grid tr:nth-child(' + (row + 1) + ') td:nth-child(' + (col + 1) + ') div')
      cell.attr('contenteditable', 'false')
      cell.text(letter)
      cell.attr('class', 'square letter')
      cell.attr('letter', letter)
    }
  })
}


function handlePuzzleEvents (puzzle, targetDiv) {
  $(targetDiv).on('keyup', '.letter', function () {
    var this_text = $(this).text()
    if (this_text.length > 1) {
      $(this).text(this_text.slice(0, 1))
    }
    // Check if a letter was entered
    if (this_text.length > 0) {
      // Move to the next cell with the same answer number
      moveCursorToNextCellWithSameNumber(puzzle, targetDiv, this)
    }
  })

  $(targetDiv).on('click', '.letter', function () {
    document.execCommand('selectAll', false, null)

    $(targetDiv + ' .letter').removeClass('active')
    $(this).addClass('active')

    $(targetDiv + ' .hint').css('color', 'initial')

    var question_numbers = String($(this).data('number')).split(',')

    $.each(question_numbers, function () {
      $(
        targetDiv +
          ' .hints .hint:nth-child(' +
          this +
          ')'
      ).css('color', 'red')
    })
  })

  $(targetDiv + ' #clear_all').click(function () {
    $(targetDiv + ' td div.editable').text('')
    if ($(targetDiv + ' #success-message-confetti').length > 0) {
      $(targetDiv + ' #success-message-confetti').remove()
    }
    if ($(targetDiv + ' .message.active').length > 0) {
      $(targetDiv + ' .message').removeClass('active')
      $(targetDiv + ' #message').text('')
    }
  })

  $(targetDiv + ' #check').click(function () {
    $(targetDiv + ' td div').css('color', 'initial')

    var isAnyLetterEntered = false
    var incorrectQuestionNumbers = []

    for (var i = 0; i < puzzle.answers.length; i++) {
      var result = checkAnswer(puzzle, i + 1, targetDiv)
      if (result !== null) {
        incorrectQuestionNumbers.push(result)
      }
      if (
        $(targetDiv + ' .letter[data-number="' + (i + 1) + '"]').text().trim() !==
        ''
      ) {
        isAnyLetterEntered = true
      }
    }

    if (!isAnyLetterEntered) {
      displayMessage(puzzle, false, ['Felder ausfüllen.'], targetDiv)
    } else {
      displayMessage(
        puzzle,
        incorrectQuestionNumbers.length === 0,
        incorrectQuestionNumbers,
        targetDiv
      )
    }
  })

  $(targetDiv + ' #clue').click(function () {
    if (!$(targetDiv + ' .letter.active').length) return
    // eslint-disable-next-line camelcase
    const question_numbers = String(
      $(targetDiv + ' .letter.active').data('number')
    ).split(',')
    showClue(
      question_numbers[0],
      $(targetDiv + ' .letter.active').parent().index(),
      $(targetDiv + ' .letter.active').parent().parent().index()
    )
  })

  function moveCursorToNextCellWithSameNumber (puzzle, targetDiv, currentCell) {
    const currentNumbers = $(currentCell).data('number').toString().split(',')
    const allCells = $(targetDiv + ' .letter')

    // Find the next editable cell with the same answer number(s)
    for (let i = allCells.index(currentCell) + 1; i < allCells.length; i++) {
      const nextCell = allCells.eq(i)
      // eslint-disable-next-line no-var
      var nextNumbers = $(nextCell).data('number').toString().split(',')
      const nextCellValue = $(nextCell).text().trim()

      // Check if any of the current numbers match any of the next numbers
      const intersection = currentNumbers.filter(value => nextNumbers.includes(value))

      if (intersection.length > 0 && (nextCellValue === '' || nextCellValue === '0')) {
        // Found the next editable cell
        $(nextCell).focus()
        break
      }
    }
  }
}

function checkAnswer (puzzle, question_number, targetDiv) {
  // eslint-disable-next-line camelcase
  const question_answer = puzzle.answers[question_number - 1].toLowerCase()
  const direction = get_direction(puzzle, question_number)
  const startpos = get_startpos(puzzle, question_number, direction)
  let isCorrect = true

  // eslint-disable-next-line eqeqeq
  if (direction == 'horizontal') {
    // eslint-disable-next-line no-var
    for (var i = 0; i < question_answer.length; i++) {
      // eslint-disable-next-line no-var
      var cell = $(targetDiv + ' tr:nth-child(' + (startpos[0] + 1) + ') td:nth-child(' + (startpos[1] + 1 + i) + ') div')
      var userLetter = cell.text().toLowerCase()

      // Skip empty cells
      if (userLetter === '') {
        continue
      }

      if (userLetter !== question_answer[i]) {
        cell.css('color', 'red')
        isCorrect = false
        break
      }
    }
  } else if (direction == 'vertical') {
    // eslint-disable-next-line no-var, no-redeclare
    for (var i = 0; i < question_answer.length; i++) {
      // eslint-disable-next-line no-var, no-redeclare
      var cell = $(targetDiv + ' tr:nth-child(' + (startpos[1] + 1 + i) + ') td:nth-child(' + (startpos[0] + 1) + ') div')
      // eslint-disable-next-line no-var, no-redeclare
      var userLetter = cell.text().toLowerCase()

      // Skip empty cells
      if (userLetter === '') {
        continue
      }

      if (userLetter !== question_answer[i]) {
        cell.css('color', 'red')
        isCorrect = false
        break
      }
    }
  }

  return isCorrect ? null : question_number
}

function showClue (puzzle, question_number, row, col) {
  const direction = get_direction(puzzle, question_number)
  const startpos = get_startpos(puzzle, question_number, direction)

  let clue = 'No clue available.' // Default message

  if (direction == 'horizontal' && startpos[0] === row) {
    clue = puzzle.clues[question_number - 1]
  } else if (direction == "vertical" && startpos[1] === col) {
    clue = puzzle.clues[question_number - 1]
  }

  // eslint-disable-next-line no-undef
  alert(clue) // You can replace this with your preferred way of displaying clues
}

function displayMessage (puzzle, isCorrect, incorrectQuestionNumbers, targetDiv) {
  let message

  if (isCorrect) {
    const incorrectRows = []
    let allAnswersCorrect = true

    for (var i = 0; i < puzzle.answers.length; i++) {
      const answer = puzzle.answers[i].toLowerCase()
      const direction = get_direction(puzzle, i + 1) // Adjusted to use 0-indexed puzzle
      const startpos = get_startpos(puzzle, i + 1, direction) // Adjusted to use 0-indexed puzzle
      let userAnswer = ''

      if (direction === 'horizontal') {
        // Horizontal answer
        for (var j = 0; j < answer.length; j++) {
          // eslint-disable-next-line no-var
          var cell = $(targetDiv + ' .puzzle-grid tr:nth-child(' + (startpos[0] + 1) + ') td:nth-child(' + (startpos[1] + 1 + j) + ') div')
          if (cell.length > 0) {
            userAnswer += cell.text().toLowerCase()
          }
        }
      } else if (direction === 'vertical') {
        // Vertical answer
        for (var j = 0; j < answer.length; j++) {
          // eslint-disable-next-line no-var, no-redeclare
          var cell = $(targetDiv + ' .puzzle-grid tr:nth-child(' + (startpos[1] + 1 + j) + ') td:nth-child(' + (startpos[0] + 1) + ') div')
          if (cell.length > 0) {
            userAnswer += cell.text().toLowerCase()
          }
        }
      }

      if (userAnswer !== answer) {
        // If the answer is incorrect, add the row number to the list of incorrect rows
        incorrectRows.push(i + 1)
        allAnswersCorrect = false
      } else {
        // If the answer is correct, add a class to the corresponding info description
        $(targetDiv + ' .info-' + (i + 1)).addClass('active')
        $(targetDiv + ' .info').addClass('active')
      }
    }

    if (allAnswersCorrect) {
      // All answers are correct, append a new div
      $(targetDiv).append('<div id="success-message-confetti"></div>')
      $(targetDiv + ' .info div').addClass('active')
      $(targetDiv + ' .puzzle-container').addClass('active')
      $(targetDiv + ' .message').removeClass('active')
      $(targetDiv + ' .correct').removeClass('active')
      $(targetDiv + ' .try-again').removeClass('active')
      createConfetti()
    } else {
      message = 'Richtig!'
      $(targetDiv + ' .correct').addClass('active')
      $(targetDiv + ' .try-again').removeClass('active')
    }
  } else {
    message = incorrectQuestionNumbers.join(', ')
    $(targetDiv + ' .try-again').addClass('active')
    $(targetDiv + ' .correct').removeClass('active')
    $(targetDiv + ' .try-again span').text(message)
  }
}

for (let i = 0; i < puzzles.length; i++) {
  const targetDiv = '#puzzle' + (i + 1)
  $(targetDiv).addClass('puzzle-container')

  drawPuzzle(puzzles[i], targetDiv)
  drawHints(puzzles[i], targetDiv)
  handlePuzzleEvents(puzzles[i], targetDiv)
  fillCorrectAnswers(puzzles[i], targetDiv)
  fillPreFilledLetters(0, '#puzzle1')
  fillPreFilledLetters(1, '#puzzle2')
  fillPreFilledLetters(2, '#puzzle3')
  fillPreFilledLetters(3, '#puzzle4')
  fillPreFilledLetters(4, '#puzzle5')
  fillPreFilledLetters(5, '#puzzle6')
  fillPreFilledLetters(6, '#puzzle7')
  fillPreFilledLetters(7, '#puzzle8')
}

function getRandomPosition () {
  return Math.random() * window.innerWidth
}

function getRandomColor () {
  const colors = ['#f39c12', '#e74c3c', '#3498db', '#2ecc71']
  return colors[Math.floor(Math.random() * colors.length)]
}

function createConfetti () {
  const confettiContainer = document.getElementById('success-message-confetti')
  for (let i = 0; i < 100; i++) {
    const confetti = document.createElement('div')
    confetti.className = 'confetti'
    confetti.style.left = `${getRandomPosition()}px`
    confetti.style.backgroundColor = getRandomColor()
    confetti.style.animationDuration = `${Math.random() * 2 + 1}s`
    confettiContainer.appendChild(confetti)

    confetti.addEventListener('animationend', () => {
      confetti.style.opacity = '0'
      setTimeout(() => {
        confetti.remove()
      }, 5000)
    })
  }

  setTimeout(() => {
    confettiContainer.style.opacity = '0'
    setTimeout(() => {
      confettiContainer.remove()
    }, 1000)
  }, 5000)
}

$('.preview ul').children().attr('contenteditable', 'true')

$('.preview ul li').on('click', function () {
  $(this).empty();
})


$(document).ready(function () {
  $('.icon-group').on('click', function () {
    $(this).parent().toggleClass('active')
  })
})

$(document).ready(function () {
  $('.process-plan--item .close').on('click', function () {
    $(this).parent().removeClass('show')
    $('.overlay').removeClass('active')
  })
})

$('.content-group a').click(function () {
  const classClicked = $(this).attr('class')
  $('.neos-contentcollection .process-plan--item').removeClass('show')
  $('.item-' + classClicked).addClass('show')
  if ($('.neos-contentcollection .process-plan--item').hasClass('show')) {
    $('.overlay').addClass('active')
  } else {
    $('.overlay').removeClass('active')
  }
})
const closePopup = document.getElementById('overlay-wrapper')

closePopup.addEventListener('click', function () {
  $('.overlay').removeClass('active')
  $('.overlay .neos-contentcollection div').removeClass('show')
})